import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";

const Section = styled.div`
  min-height: 100vh;
  width: 100%;
  scroll-snap-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  text-align: center;
  font-weight: 200;
`;
const Form = styled.form`
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const Input = styled.input`
  border: none;
  border-radius: 5px;
  background-color: #3a3a3a;
  color: white;
  padding: 20px;
  text-decoration: none;
`;
const TextArea = styled.textarea`
  border: none;
  border-radius: 5px;
  background-color: #3a3a3a;
  color: white;
  padding: 20px;
`;
const Button = styled.button`
  color: white;
  font-weight: bold;
  background: #1a1a1a;
  border: solid 3px white;
  border-radius: 10px;
  padding: 20px;
  &:hover {
    background-color: white;
    color: black;
    cursor: pointer;
  }
`;

const P = styled.p`
  text-align: center;
`;

const Contact = () => {
  const ref = useRef();
  const [success, setSuccess] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_a0apjak",
        "template_lt6gh8p",
        ref.current,
        "TJyxVi5iJIKAFaNyB"
      )
      .then(
        (result) => {
          console.log(result.txt);
          setSuccess(true);
        },
        (error) => {
          console.log(error.txt);
          setSuccess(false);
        }
      );
  };
  return (
    <Section id="contact">
      <Container>
        <Form ref={ref} onSubmit={handleSubmit}>
          <Title>Get in Touch!</Title>
          <Input placeholder="Name" name="name"></Input>
          <Input placeholder="Email" name="email"></Input>
          <TextArea
            placeholder="Write your message here."
            name="message"
            rows={10}
          ></TextArea>
          <Button type="submit">Send</Button>
          {success && (
            <div>
              <P>Your message has been sent, i'll get back to you ASAP!</P>
            </div>
          )}
        </Form>
      </Container>
    </Section>
  );
};

export default Contact;
