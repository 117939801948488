import React from "react";
import styled from "styled-components";

const Card = styled.div`
  display: flex;
  padding: 10px;
  max-width: 100%;
  height: 100%;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IMG = styled.img`
  max-height: 90%;
  border-radius: 20px;
`;

const CenterDiv = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const H1 = styled.h1`
  font-size: 70px;
  margin: 0;
`;

const H2 = styled.h2`
  margin: 0;
`;

const TextSection = styled.div`
  margin-top: 10px;
  width: 80%;
  display: flex;
  flex-direction: column;
`;
const Paragraph = styled.p`
  @media (max-width: 1400px) {
    font-size: 15px;
  }
  @media (min-width: 1400px) {
    font-size: 20px;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.a`
  margin: 10px;
  text-decoration: none;
  background-color: #1a1a1a;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  border: solid 3px white;
  padding: 20px;
  &:hover {
    background-color: white;
    color: black;
    cursor: pointer;
  }
`;
const Skilldiv = styled.div`
  display: flex;
  max-width: 100%;
  margin-top: 20px;
`;
const Skill = styled.a`
  padding: 12px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 20px;
  background-color: #4c7b07;
  color: White;
`;

function ProjectCardSafeSpace() {
  return (
    <Card>
      <Left>
        <IMG src="./img/PortSafeSpace.png"></IMG>
      </Left>
      <CenterDiv>
        <Right>
          <H1>SAFESPACE</H1>
          <H2>A MACHINE LEARNING POWERED APP</H2>
          <Skilldiv>
            <Skill>KOTLIN</Skill>
            <Skill>ANDROID</Skill>
            <Skill>TENSORFLOW</Skill>
            <Skill>MONGODB</Skill>
            <Skill>SCRUM</Skill>
          </Skilldiv>
          <TextSection>
            <Paragraph>
              Safe Space is a comprehensive (web) application that enables users
              to gauge their comfort and safety levels in specific
              neighborhoods. This is facilitated by a sophisticated algorithm
              that takes into account user-specified preferences. The data
              driving this algorithm is directly sourced from the Dutch
              government, consisting of reports regarding incidents and criminal
              activities reported to the police, and is routinely updated on a
              monthly basis. This robust algorithm excels at predicting an
              individual's sense of security at a given location, leveraging
              available data and feedback from other users. In terms of privacy,
              Safe Space prioritizes the protection of user data. No personal
              information is collected, ensuring that user preferences are
              processed anonymously within the algorithm. This stringent
              approach makes it impossible to trace specific input back to any
              individual user. Throughout the development phase, my primary
              responsibilities included the creation of the Android App. This
              application was designed to seamlessly communicate with the
              underlying MongoDB through a REST API. Additionally, I took charge
              of crafting a Proof of Concept (POC) for our machine learning
              component. This endeavor involved the utilization of TensorFlow
              Lite and a focus on Computer Vision techniques.<br></br>
              <br></br>
              Created along with: Robin Muijs, Tim Bahn, Robin Sitters & Shad
              Khan
            </Paragraph>
          </TextSection>
          <ButtonDiv>
            <Button href="https://github.com/Kr0sFyr3/SafeSpace-ADAINF">
              GITHUB
            </Button>
            <Button href="http://www.bpsafespace.nl">WEBSITE</Button>
            <Button href="https://www.figma.com/proto/cZTn5UOj465xed173VtGfK/SafeSpace-Prototypes?page-id=0%3A1&type=design&node-id=1-4&viewport=357%2C611%2C0.44&t=1DEbIFGwU7U2HDYr-1&scaling=scale-down&starting-point-node-id=1%3A4&show-proto-sidebar=1&mode=design">
              PROTOTYPE
            </Button>
          </ButtonDiv>
        </Right>
      </CenterDiv>
    </Card>
  );
}

export default ProjectCardSafeSpace;
