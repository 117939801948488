import React from "react";
import styled from "styled-components";
import Navbar from "./Navbar";
import gsap from "gsap";

const SectionShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  background: url("./img/bg.jpg");
  background-size: cover;
  z-index: -1;
  --mask: radial-gradient(
    circle at var(--x) var(--y),
    black 10%,
    transparent 0
  );
  -webkit-mask-image: var(--mask);
  mask-image: var(--mask);
  transition: --x 300ms 20ms ease-out, --y 300ms 20ms ease-out;
`;

const Div = styled.div`
  margin-top: 50px;
  text-align: center;
`;

const H1 = styled.h1`
  width: 100vw;
  padding: 0;
  margin: 0;
  display: inline-block;
  font-size: calc(18vh - 50px);
`;

const H3 = styled.h3`
  color: white;
`;

const Landing = () => {
  let shadow = document.querySelector("#SectionShadow");
  window.addEventListener("mousemove", (e) => {
    shadow = document.querySelector("#SectionShadow");
    shadow.style.zIndex = "0";
    const { clientX, clientY } = e;
    const x = Math.round((clientX / window.innerWidth) * 100);
    const y = Math.round((clientY / window.innerHeight) * 100);

    gsap.to(shadow, {
      "--x": `${x}%`,
      "--y": `${y}%`,
      duration: 0.5,
      ease: "sine.out",
    });
  });
  return (
    <SectionShadow id="SectionShadow">
      <Navbar logosrc={"./img/logobw.png"} />
      <Div>
        <H1>ARE YOU LOOKING FOR A</H1>
        <H1>TECHNICAL AND</H1>
        <H1>CREATIVE MIND?</H1>
        <H3>SCROLL DOWN</H3>
      </Div>
    </SectionShadow>
  );
};

export default Landing;
