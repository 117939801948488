import React from "react";
import styled from "styled-components";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Sphere, MeshDistortMaterial } from "@react-three/drei";

const Section = styled.div`
  height: 100vh;
  scroll-snap-align: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const Text = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
`;
const Title = styled.h1`
  font-size: 50px;
  padding: 0;
  margin: 0;
`;
const SubTitle = styled.h2`
  font-size: 20px;
  padding: 0;
  margin: 0;
`;

const Paragraph = styled.p`
  width: 80%;
`;

const ThreeD = styled.div`
  position: relative;
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const AbsoluteImgDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: 500px;
  height: 500px;
  animation: animate 2s infinite ease alternate;
  @keyframes animate {
    to {
      transform: translateY(15px);
    }
  }
`;

const A = styled.a`
  font-weight: bold;
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 10px;
  width: 150px;
  border-radius: 10px;
  border: solid 3px white;
  &:hover {
    background-color: white;
    color: black;
  }
`;

const AboutAndContact = () => {
  return (
    <Section id="about">
      <Content>
        <Text>
          <Title>Paul Kros</Title>
          <SubTitle>- Creative Developer</SubTitle>
          <Paragraph>
            I am a problem-solving and creative thinker, passionate about
            developing well designed IT solutions. Having specialized through my
            studies in app development, i love creating high-quality and
            visually appealing applications in Kotlin, Java, and React Native.
            I’ve already crafted applications for WV Arne, Het Wapen van
            Roosendaal, and SafeSpace with lots of love.
          </Paragraph>
          <Paragraph>
            Currently set to graduate with honors from the Associate Degree
            Academy, I would describe myself as a hard worker who's always
            looking for opportunities to grow and learn, eager to take on new
            challenges and learn new things.
          </Paragraph>
          <A href="https://links.paulkros.nl">ME ON THE WEB</A>
        </Text>
        <ThreeD>
          <Canvas camera={{ fov: 100, position: [5, 5, 5] }}>
            <OrbitControls enableZoom={false} autoRotate />
            <ambientLight intensity={1} />
            <directionalLight position={(3, 2, 1)} />
            <Sphere args={[1, 100, 500]} scale={4.6}>
              <MeshDistortMaterial
                color="#6ead12"
                attach="material"
                distort={0.5}
                speed={1.4}
              />
            </Sphere>
          </Canvas>
          <AbsoluteImgDiv>
            <Img src="./img/facevectorimage.png" alt="FACEVECTOR NOT LOADED" />
          </AbsoluteImgDiv>
        </ThreeD>
      </Content>
    </Section>
  );
};

export default AboutAndContact;
