import React from "react";
import styled from "styled-components";

const Card = styled.div`
  display: flex;
  padding: 10px;
  max-width: 100%;
  height: 100%;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IMG = styled.img`
  max-height: 90%;
  border-radius: 20px;
`;

const CenterDiv = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const H1 = styled.h1`
  font-size: 70px;
  margin: 0;
`;

const H2 = styled.h2`
  margin: 0;
`;

const TextSection = styled.div`
  margin-top: 10px;
  width: 80%;
  display: flex;
  flex-direction: column;
`;
const Paragraph = styled.p`
  @media (max-width: 1400px) {
    font-size: 15px;
  }
  @media (min-width: 1400px) {
    font-size: 20px;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.a`
  margin: 10px;
  text-decoration: none;
  background-color: #1a1a1a;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  border: solid 3px white;
  padding: 20px;
  &:hover {
    background-color: white;
    color: black;
    cursor: pointer;
  }
`;
const Skilldiv = styled.div`
  display: flex;
  max-width: 100%;
  margin-top: 20px;
`;
const Skill = styled.a`
  padding: 12px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 20px;
  background-color: #4c7b07;
  color: White;
`;

function ProjectCardOldPort() {
  return (
    <Card>
      <Left>
        <IMG src="./img/PortOud.png"></IMG>
      </Left>
      <CenterDiv>
        <Right>
          <H1>PORTFOLIO V1</H1>
          <H2>WEB DEVELOPMENT</H2>
          <Skilldiv>
            <Skill>HTML</Skill>
            <Skill>CSS</Skill>
            <Skill>JAVASCRIPT</Skill>
            <Skill>PHP</Skill>
            <Skill>SQL</Skill>
          </Skilldiv>
          <TextSection>
            <Paragraph>
              This is a portfolio website created for the first semester of the
              AD Informatica at the Associate Degree Academy in Roosendaal. This
              website was made using HTML, CSS, JavaScript, and PHP. It was also
              my first time working with Databases & SQL and i managed to learn
              a lot about how to properly work with Databases. Additionally, the
              Bootstrap framework was utilized throughout the development
              process. During the creation of this website, I learned a lot
              about web development and I am excited to continue developing my
              skills in these areas.
            </Paragraph>
          </TextSection>
          <ButtonDiv>
            <Button href="https://portfolio.adainforma.tk/paul/">
              WEBSITE
            </Button>
          </ButtonDiv>
        </Right>
      </CenterDiv>
    </Card>
  );
}

export default ProjectCardOldPort;
