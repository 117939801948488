import "./App.css";
import Landing from "./components/Landing";
import LandingShadow from "./components/LandingShadow";
import AboutAndContact from "./components/AboutAndContact";
import Work from "./components/Work";
import Contact from "./components/Contact";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  color: white;
  background-color: #1a1a1a;m
  &::-webkit-scrollbar {
    display: none;
  }

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

function App() {
  return (
    <Container>
      <Landing />
      <LandingShadow />
      <AboutAndContact />
      <Work />
      <Contact />
    </Container>
  );
}

export default App;
