import React from "react";
import styled from "styled-components";

const Card = styled.div`
  display: flex;
  padding: 10px;
  max-width: 100%;
  height: 100%;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IMG = styled.img`
  max-height: 90%;
  border-radius: 20px;
`;

const CenterDiv = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const H1 = styled.h1`
  font-size: 70px;
  margin: 0;
`;

const H2 = styled.h2`
  margin: 0;
`;

const TextSection = styled.div`
  margin-top: 10px;
  width: 80%;
  display: flex;
  flex-direction: column;
`;
const Paragraph = styled.p`
  @media (max-width: 1400px) {
    font-size: 15px;
  }
  @media (min-width: 1400px) {
    font-size: 20px;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.a`
  margin: 10px;
  text-decoration: none;
  background-color: #1a1a1a;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  border: solid 3px white;
  padding: 20px;
  &:hover {
    background-color: white;
    color: black;
    cursor: pointer;
  }
`;
const Skilldiv = styled.div`
  display: flex;
  max-width: 100%;
  margin-top: 20px;
`;
const Skill = styled.a`
  padding: 12px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 20px;
  background-color: #4c7b07;
  color: White;
`;

function ProjectCardHetWapen() {
  return (
    <Card>
      <Left>
        <IMG src="./img/PortWapen.png"></IMG>
      </Left>
      <CenterDiv>
        <Right>
          <H1>HET WAPEN</H1>
          <H2>ANDROID APP</H2>
          <Skilldiv>
            <Skill>KOTLIN</Skill>
            <Skill>ANDROID</Skill>
            <Skill>REST</Skill>
            <Skill>SQL</Skill>
            <Skill>FIGMA</Skill>
          </Skilldiv>
          <TextSection>
            <Paragraph>
              During my second semester in the Informatica course at the
              Associate Degree Academie in Roosendaal, I was an integral part of
              a dynamic development team that created an app for Het Wapen van
              Roosendaal. This Android application, powered by Kotlin, showcased
              our combined proficiency in REST API integration and database
              connectivity. Additionally, I played a key role in the app's
              design using Figma, contributing to a seamless user experience.
              <br></br>
              <br></br>Together with my teammate, we followed a client-centric
              approach, eliciting requirements based on the Moscow method. This
              allowed us to establish a clear roadmap for the project.
              Subsequently, I contributed to the creation of a Styleguide and
              Prototype, ensuring a cohesive and visually appealing interface.
              The positive feedback from the client affirmed the effectiveness
              of our design and development approach. Working alongside my
              teammate, we adeptly employed Kotlin's versatility to implement
              REST API calls and robust database functionality. The result was a
              highly functional, user-friendly application that successfully
              brought the client's Craft Beer Subscription service to life.
              <br></br>
              <br></br>
              Created along with: Robin Muijs
            </Paragraph>
          </TextSection>
          <ButtonDiv>
            <Button href="https://www.figma.com/proto/fUMAOJnNUh7sU7urCVirFd/Prototype-Het-Wapen-ADA?page-id=0%3A1&type=design&node-id=1-3&viewport=594%2C480%2C0.27&t=CtR7GXHthLRNCoRt-1&scaling=scale-down&starting-point-node-id=1%3A2&mode=design">
              PROTOTYPE
            </Button>
          </ButtonDiv>
        </Right>
      </CenterDiv>
    </Card>
  );
}

export default ProjectCardHetWapen;
