import React, { useRef } from "react";
import styled from "styled-components";
import ProjectCardArie from "./ProjectCardArie";
import ProjectCardSafeSpace from "./ProjectCardSafeSpace";
import ProjectCardHetWapen from "./ProjectCardHetWapen";
import ProjectCardOldPort from "./ProjectCardOldPort";
import ProjectCardUma from "./ProjectCardUma";

const Section = styled.div`
  scroll-snap-align: start;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Grid = styled.div`
  display: flex;
`;

const CarouselContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  width: 100vw;
  height: 100vh;
`;

const CarouselItem = styled.div`
  scroll-snap-align: start;
  overflow-y: hidden;
  flex-shrink: 0;
  width: 100vw;
`;

const NavButton = styled.button`
  position: absolute;
  top: 95%;
  font-size: 20px;
  color: white;
  background: none;
  border: none;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  z-index: 10;
  transform: translateY(-50%);
`;

const PrevButton = styled(NavButton)`
  left: 45vw;
  font-weight: bold;
`;

const NextButton = styled(NavButton)`
  right: 45vw;
  font-weight: bold;
`;

const Work = () => {
  const carouselRef = useRef(null);

  const scrollToNext = () => {
    console.log(carouselRef.current);
    if (carouselRef.current) {
      carouselRef.current.scrollLeft += window.innerWidth;
    }
  };

  const scrollToPrev = () => {
    console.log(carouselRef.current);
    if (carouselRef.current) {
      carouselRef.current.scrollLeft -= window.innerWidth;
    }
  };

  return (
    <Section id="work">
      <Grid>
        <PrevButton onClick={scrollToPrev}>&#8592;</PrevButton>
        <CarouselContainer ref={carouselRef}>
          <CarouselItem>
            <ProjectCardHetWapen></ProjectCardHetWapen>
          </CarouselItem>
          <CarouselItem>
            <ProjectCardSafeSpace></ProjectCardSafeSpace>
          </CarouselItem>
          <CarouselItem>
            <ProjectCardArie></ProjectCardArie>
          </CarouselItem>
          <CarouselItem>
            <ProjectCardUma></ProjectCardUma>
          </CarouselItem>
          <CarouselItem>
            <ProjectCardOldPort></ProjectCardOldPort>
          </CarouselItem>
        </CarouselContainer>
        <NextButton onClick={scrollToNext}>&#8594;</NextButton>
      </Grid>
    </Section>
  );
};

export default Work;
