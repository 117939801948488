import React from "react";
import styled from "styled-components";

const Card = styled.div`
  display: flex;
  padding: 10px;
  max-width: 100%;
  height: 100%;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IMG = styled.img`
  max-height: 90%;
  border-radius: 20px;
`;

const CenterDiv = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const H1 = styled.h1`
  font-size: 70px;
  margin: 0;
`;

const H2 = styled.h2`
  margin: 0;
`;

const TextSection = styled.div`
  margin-top: 10px;
  width: 80%;
  display: flex;
  flex-direction: column;
`;
const Paragraph = styled.p`
  @media (max-width: 1400px) {
    font-size: 15px;
  }
  @media (min-width: 1400px) {
    font-size: 20px;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.a`
  margin: 10px;
  text-decoration: none;
  background-color: #1a1a1a;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  border: solid 3px white;
  padding: 20px;
  &:hover {
    background-color: white;
    color: black;
    cursor: pointer;
  }
`;
const Skilldiv = styled.div`
  display: flex;
  max-width: 100%;
  margin-top: 20px;
`;
const Skill = styled.a`
  padding: 12px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 20px;
  background-color: #4c7b07;
  color: White;
`;

function ProjectCardArie() {
  return (
    <Card>
      <Left>
        <IMG src="./img/PortArie.png"></IMG>
      </Left>
      <CenterDiv>
        <Right>
          <H1>ARIE PRINSEN</H1>
          <H2>A SOCIAL MEDIA HOAX</H2>
          <TextSection>
            <Paragraph>
              "Arie Prinsen" is a hoax our team created for a project during the
              second year of Communication & Multimedia Design at Avans Breda.
              The goal of this hoax is to raise awareness among children about
              the dangers of false information spread through social media.
              Children are now exposed to the internet and social media at an
              early age. News spreads rapidly among this age group,
              unfortunately, including 'fake' news. To make children, as well as
              parents and teachers, aware of this, we have developed this hoax.
              This is included in a 3-day program that groups 7 and 8 in primary
              school can participate in.'<br></br>
              <br></br>
              Created along with: Stella Happel, Lisa Ter Horst, Denise Eikhoudt
              & Lars Gobbens
            </Paragraph>
          </TextSection>
          <ButtonDiv>
            <Button href="https://vimeo.com/653389684">AFTERMOVIE</Button>
            <Button href="https://www.instagram.com/arieprinsen/">
              @ARIEPRINSEN
            </Button>
          </ButtonDiv>
        </Right>
      </CenterDiv>
    </Card>
  );
}

export default ProjectCardArie;
