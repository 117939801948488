import React from "react";
import styled from "styled-components";

const Section = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  padding: 20px 0px;
  width: 90vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  color: #96c93d;
`;

const Logo = styled.img`
  height: 50px;
`;

const List = styled.ul`
  display: flex;
  gap: 20px;
  list-style: none;
`;

const Atag = styled.a`
  cursor: pointer;
  color: #96c93d;
  text-decoration: none;
  &:hover {
    color: white;
  }
`;

const ListItem = styled.li`
  cursor: pointer;
  &:hover {
    color: white;
  }
`;

function Navbar({ logosrc }) {
  return (
    <Section>
      <Container>
        <Links>
          <Logo src={logosrc} />
          <List>
            <Atag href="#about">
              <ListItem>About</ListItem>
            </Atag>
            <Atag href="#work">
              <ListItem>Work</ListItem>
            </Atag>
            <Atag href="#contact">
              <ListItem>Contact</ListItem>
            </Atag>
          </List>
        </Links>
      </Container>
    </Section>
  );
}

export default Navbar;
