import React from "react";
import styled from "styled-components";

const Card = styled.div`
  display: flex;
  padding: 10px;
  max-width: 100%;
  height: 100%;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IMG = styled.img`
  max-height: 90%;
  border-radius: 20px;
`;

const CenterDiv = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const H1 = styled.h1`
  font-size: 70px;
  margin: 0;
`;

const H2 = styled.h2`
  margin: 0;
`;

const TextSection = styled.div`
  margin-top: 10px;
  width: 80%;
  display: flex;
  flex-direction: column;
`;
const Paragraph = styled.p`
  @media (max-width: 1400px) {
    font-size: 15px;
  }
  @media (min-width: 1400px) {
    font-size: 20px;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.a`
  margin: 10px;
  text-decoration: none;
  background-color: #1a1a1a;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  border: solid 3px white;
  padding: 20px;
  &:hover {
    background-color: white;
    color: black;
    cursor: pointer;
  }
`;
const Skilldiv = styled.div`
  display: flex;
  max-width: 100%;
  margin-top: 20px;
`;
const Skill = styled.a`
  padding: 12px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 20px;
  background-color: #4c7b07;
  color: White;
`;

function ProjectCardUma() {
  return (
    <Card>
      <Left>
        <IMG src="./img/PortUma.png"></IMG>
      </Left>
      <CenterDiv>
        <Right>
          <H1>UMAREKAWARI</H1>
          <H2>VR EXPERIENCE</H2>
          <Skilldiv>
            <Skill>C#</Skill>
            <Skill>UNITY</Skill>
            <Skill>BLENDER</Skill>
            <Skill>SCRUM</Skill>
          </Skilldiv>
          <TextSection>
            <Paragraph>
              Umarekawari is a virtual reality (VR) game set in the year 2050,
              where players step into the shoes of a young Japanese girl deeply
              rooted in ancient traditions and culture. Embracing Buddhism, the
              girl finds herself amidst a world where this belief system has
              waned significantly. Through the immersive medium of VR, she
              embarks on a journey to explore her faith and forge a stronger
              connection with it. The game incorporates a unique meta-element,
              as players don a VR headset to enter the virtual realm. This added
              layer enriches the narrative, serving as a powerful storytelling
              tool that seamlessly allows players to switch between
              perspectives, providing an intricate exploration of the futuristic
              Tokyo landscape. Umarekawari emerged as the brainchild of a
              collaborative effort during the Time Based Storytelling module for
              the Communication & Multimedia Design program. Tasked with
              envisioning a game for "Cities of the Future," our team was
              assigned Tokyo as our canvas. This project proved to be an
              invaluable learning experience, where I gained proficiency in
              agile development practices such as Scrum, honed skills in 3D
              modeling, delved into the fundamental principles of Virtual
              Reality, and grasped the basics of the Unity Game Engine. This
              project not only serves as an immersive gaming experience but also
              as a cultural bridge, inviting players to navigate the intricate
              balance between tradition and modernity in the dynamic backdrop of
              2050 Tokyo. Through Umarekawari, we aim to not only entertain but
              also inspire players to contemplate the evolving landscapes of
              faith and culture in a rapidly changing world.
            </Paragraph>
          </TextSection>
          <ButtonDiv>
            <Button href="https://youtu.be/Fl6lJqFvC7o">DEMO</Button>
          </ButtonDiv>
        </Right>
      </CenterDiv>
    </Card>
  );
}

export default ProjectCardUma;
