import React from "react";
import styled from "styled-components";
import Navbar from "./Navbar";

const Section = styled.div`
  display: flex;
  height: 100vh;
  scroll-snap-align: center;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  background-color: black;
`;

const Div = styled.div`
  margin-top: 50px;
  text-align: center;
`;

const H1 = styled.h1`
  width: 100vw;
  padding: 0;
  margin: 0;
  display: inline-block;
  font-size: calc(18vh - 50px);
  background: radial-gradient(circle at center, #96c93d 0.1rem, transparent 0);
  background-size: 2rem 2rem;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`;

const H3 = styled.h3`
  background: radial-gradient(circle at center, #96c93d 0.1rem, transparent 0);
  background-size: 2rem 2rem;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`;

const Landing = () => {
  return (
    <Section id="Section">
      <Navbar logosrc={"./img/logo.png"} />
      <Div>
        <H1>ARE YOU LOOKING FOR A</H1>
        <H1>TECHNICAL AND</H1>
        <H1>CREATIVE MIND?</H1>
        <H3>SCROLL DOWN</H3>
      </Div>
    </Section>
  );
};

export default Landing;
